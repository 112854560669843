.tt_feed {
    padding-right: 8px;
    &:hover {
        // background-color: #f5f5f5;
        box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
            0px 1px 8px 0px rgb(0 0 0 / 12%);

        & .rc-collapse-content {
            background-color: #f5f5f5;
        }
    }

    --balloon-font-size: 18px;
}

.tt_feed_avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 58px;
    padding-left: 10px;
    padding-top: 21px; // * 对齐primary stories的title的下沿, 如果title比较长，占两行，按第一行算
    padding-right: 16px;

    .MuiAvatar-root.MuiAvatar-square {
        width: 32px;
        height: 32px;
    }
}

.tt_feed_src {
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    letter-spacing: 1.5px;

    margin-top: 19px;
}

.tt_feed_title {
    & > span {
        display: flex;
        a {
            color: rgba(0, 0, 0, 0.87);
            width: 100%;

            &:hover {
                color: #024d8f;
            }
        }
    }
}

.tt_feed_footer {
    margin-top: 11px;
    margin-bottom: 10px;
    height: 21px;

    display: flex;
    justify-content: space-between;

    .tt_feed_tags {
        height: 100%;
        line-height: 21px;
        letter-spacing: 0.25px;

        & > .tt_feed_tag {
            margin-right: 8px;
            outline: 0;
            border: 0;
            background-color: unset;
            text-transform: uppercase;
            color: #21619a;
            padding: 0;
            cursor: pointer;
            font-family: sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            transition: transform 0.2s ease-in-out;
            &:hover {
                transform: scale(1.3);
            }
        }
    }

    .tt_feed_actions {
        width: 110px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
            cursor: pointer;
        }
    }
}
