.MuiTimeline-root {
    padding: 0;
}

.MuiTimelineSeparator-root {
    flex-direction: column-reverse;
}

.MuiTimelineItem-root {
    &::before {
        content: none;
    }
}

.tt_similar_story {
    margin-top: 10px;
}

.tt_simiar_story_title {
    min-height: 21px;

    margin-left: 22px;
    margin-top: 0;
    margin-bottom: 0;

    & .MuiLink-root {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
    }

    & > span,
    & > span > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.tt_similar_story_avatar {
    height: 21px !important;
    width: 21px !important;
}

.similar-stories-collapse {
    width: 100%;
    border: none;

    & .rc-collapse-content {
        background-color: #fbfcfd;
        padding: 0;
    }
}

.similar-stories-collapse-header {
    & > svg.MuiSvgIcon-root {
        margin-right: 22px;
    }
}
