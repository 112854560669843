.SideNav-items {
    padding-top: 12px;
    padding-left: 8px;
    padding-right: 8px;

    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    flex-grow: 1;
    overflow-y: scroll;
}

.SideNav-item {
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000;

    &:hover {
        background-color: #f2f9fe;
    }

    &.Mui-selected,
    &.Mui-selected:hover {
        background-color: #f2f9fe;
        color: #4482b2;

        & > .MuiListItemText-root {
            span {
                font-weight: 700;
            }
        }

        & > .MuiListItemIcon-root {
            svg {
                fill: #4482b2;
            }
        }
    }

    & .MuiSvgIcon-root {
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
    }

    &.tt-wl-expanded {
        & .MuiSvgIcon-root {
            transform: rotate(180deg);
            transition: transform 0.3s ease-in-out;
        }
    }

    &.SideNav-item__watchlist {
        &:hover {
            & > .MuiListItemText-root {
                background-color: #f2f9fe;
            }
            background-color: #fff !important;
        }
        &.Mui-selected {
            background-color: #fff !important;
            & > .MuiListItemText-root {
                background-color: #f2f9fe;
            }
        }
    }
}

.SideNav-item__watchlist__DropdownContainer {
    height: 24px;

    & > .SideNav-item__watchlist__DropdownContentContainer {
        right: 0;
        width: 200px;
        height: 112px;
        border-radius: 4px;
        background-color: #fff;
        z-index: 1;
        padding-top: 8px;
        padding-bottom: 8px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);

        &.SideNav-item__watchlist__lastitem {
            bottom: 50px;
        }

        & > div {
            width: 100%;
            height: 48px;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px 16px;
            color: #000;
            &:hover {
                background-color: #e3f2fd;
            }
        }
    }
}
