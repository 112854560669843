.Header-container {
    width: 100%;
    height: 144px;
    padding-left: 20px;
    padding-top: 25px;
}

.TickerTick_API_Banner {
    & > button {
        padding: 0px;
    }
    & .SideNav-Info__Link {
        text-transform: none;
        text-decoration: none;
        color: #4482b2;
        font-weight: 400 !important;
    }
}

.SideNav-Header-logo {
    height: 32px;
    align-items: center;

    & > svg {
        width: 146px;
        height: 32px;
        cursor: pointer;
    }

    & > span:nth-child(2) {
        color: #4482b2;
        font-style: italic;
        height: 100%;
        font-size: 14px;
    }

    & > span:nth-child(3) {
        flex-grow: 1;
    }

    & > button {
        margin-right: 8px;
        padding: 0px;
        height: 24px;
        display: flex;
        align-items: center;

        @media screen and (min-width: 1280px) {
            display: none;
        }
    }
}

.SideNav-Header-username {
    height: 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);

    margin-top: 24px;

    & > a {
        cursor: pointer;
    }
}

.logout {
    margin-right: 8px;
    cursor: pointer;
}
