.logo__container {
    margin-top: 25px;
    height: 32px;

    display: flex;
    align-items: center;

    cursor: pointer;
}

.logo__svg {
    height: 32px;
    width: 146px;
}
