html,
body {
    height: 100%;
    width: 100%;
}

body {
    padding: 0;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#app {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.v-spacer {
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

// * https://github.com/medmin/YT-tutorials
// * https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent !important;
}

::-webkit-scrollbar-thumb {
    background: rgba(68, 130, 178, 0.5);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(68, 130, 178, 1);
}

// sweetalert2
.swal2-html-container {
    overflow: auto;
}

// issue no.150
.swal2-container {
    z-index: 1111;
}
