.SideNav-Footer-Container {
    height: 237px;

    display: flex;
    flex-direction: column;
}

.SideNavFooterLongButton {
    margin: 20px 20px 0px 20px;
    border: 1px solid #67afe7;
    box-sizing: border-box;
    border-radius: 4px;

    & > a {
        text-decoration: none;
    }

    .MuiButton-label {
        color: #67afe7;
    }
}

.SideNav-Info {
    justify-content: flex-start;
    padding-left: 20px;
    padding-bottom: 20px;

    & > button {
        padding-left: 0px;
    }

    & .SideNav-Info__Link {
        text-decoration: none;
    }
}
