.SideNav-Container {
    flex: 0 0 256px; //https://stackoverflow.com/a/21413841/12061669
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    @media screen and (max-width: 1280px) {
        // display: none;
        position: fixed;
        background-color: #fff;
        z-index: 2;
        left: 0;
        width: max(300px, 40%);
    }
}
